
import { Component, Mixins } from 'vue-property-decorator';
import { AnyObject, DestinationStorageRows } from '@/types';
import HeaderInfosMixin from '../header-infos';
import RunDocMixin from '@/mixins/data-operations/doc/run-doc-mixin';
import { fileUtilitiesRuns } from '@/store/modules/easy-firestore/file-utilities-runs';
import { TaskListingExpandableProps } from '@/components/data-operations/common/item/tasks/TaskListingExpandable.vue';
import { fileUtilitiesRunsLight } from '@/store/modules/easy-firestore/file-utilities-runs-light';

@Component
export default class FileUtilitiesRunsItemView extends Mixins(HeaderInfosMixin, RunDocMixin) {
	moduleName: string = fileUtilitiesRuns.moduleName;
	moduleNameLight: string = fileUtilitiesRunsLight.moduleName;
	get itemTabsItems(): any {
		if (Object.keys(this.item).length === 0) return [];
		let tab = [
			this.runDetailsTab,
			this.runLogsTab,
			this.configurationTab,
			this.fullJSONTab,
			this.otherRunsTab,
			this.notesTab,
		] as any;
		if (
			this.item.configuration_context.configuration.tasks &&
			this.item.configuration_context.configuration.tasks.length
		) {
			const taskTab = {
				label: 'Tasks',
				href: 'tasks',
				component: {
					name: 'task-listing-expandable',
					props: {
						tasks: this.formatTasks(this.item.configuration_context.configuration.tasks),
					},
				},
			};
			tab.splice(3, 0, taskTab);
		}
		return tab;
	}

	get runDetailsData() {
		return [
			{
				component: 'view-header',
				props: {
					item: this.item,
					collection: this.moduleName,
					activeHeader: true,
					viewId: this.item.id,
					viewType: 'run',
					runStatus: this.item.status,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Run Details',
					tooltip: true,
					description: 'Details of the Storage to Storage run',
					paramItems: [
						{ id: 'account', label: 'Account', value: this.item.account },
						{ id: 'environment', label: 'Environment', value: this.item.environment },
						{
							id: 'client_type',
							label: 'Client Type',
							value: this.getFormattedClientType(this.item.configuration_context.configuration.client_type),
						},
						{
							id: 'client_version',
							label: 'Client Version',
							value: this.getFormattedClientVersion(this.item.configuration_context.configuration.client_version),
						},
						{
							id: 'matching_filename_template',
							label: 'Filename Matched',
							value: this.item.matching_filename_template,
						},
						{ id: 'triggering_file', label: 'File Triggered', value: this.item.triggering_file },
						{
							id: 'source_storage',
							label: 'Source Storage',
							value: this.item.configuration_context.configuration.gcs_bucket,
							linkPath: `https://console.cloud.google.com/storage/browser/${this.item.configuration_context.configuration.gcs_bucket}?project=${this.item.configuration_context.configuration.gcp_project_id}`,
						},
						{ id: 'job_id', label: 'Job Id', value: this.item.job_id },
						{
							id: 'dag_execution_date',
							label: 'Execution Date',
							value: this.$moment(this.item.dag_execution_date, '').format('YYYY/MM/DD - HH:mm:ss'),
						},
						{ id: 'duration', label: 'Duration', value: this.item.duration?.split('.')[0] },
						{ id: 'dag_run_id', label: 'Run Id', value: this.item.dag_run_id },
					],
				},
			},
		];
	}

	get configurationData() {
		return [
			{
				component: 'view-header',
				props: {
					item: this.item,
					collection: this.moduleName,
					activeHeader: false,
					viewId: this.item.dag_id,
					viewType: 'conf',
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Context',
					tooltip: true,
					description: 'Context of the Storage to Storage configuration',
					paramItems: [
						{ id: 'account', label: 'Account', value: this.item.account },
						{ id: 'environment', label: 'Environment', value: this.item.environment },
						{ id: 'client_type', label: 'Client Type', value: this.getFormattedClientType(this.item.client_type) },
						{
							id: 'client_version',
							label: 'Client Version',
							value: this.getFormattedClientVersion(this.item.client_version),
						},
						{
							id: 'gcp_project_id',
							label: 'GCP Project',
							value: this.item.configuration_context.configuration.gcp_project_id,
						},
						{
							id: 'gcs_bucket',
							label: 'GCS Bucket',
							value: this.item.configuration_context.configuration.gcs_bucket,
							linkPath: `https://console.cloud.google.com/storage/browser/${this.item.configuration_context.configuration.gcs_bucket}?project=${this.item.configuration_context.configuration.gcp_project_id}`,
						},
						{
							id: 'gcs_path',
							label: 'GCS Path',
							value: this.item.configuration_context.configuration.gcs_path,
						},
						{
							id: 'gcs_destination_suffix',
							label: 'Destination Suffix',
							value: this.item.configuration_context.configuration.gcs_destination_suffix,
						},
					],
				},
			},
			{
				component: 'parameters-table',
				props: {
					tableTitle: 'File Name Template(s)',
					description:
						'Templates of the incomming files that should be copied to the destination storages. Do not put the date/timestamp prefix file in the template',
					columns: [
						{
							label: 'File Name Template',
							field: 'filename_template',
						},
						{
							label: 'File Name Description',
							field: 'file_description',
						},
					],
					rows: this.item.configuration_context.configuration.filename_templates,
					vflexLength: 'xs12',
					lineNumbers: false,
					searchOptionsEnabled: false,
				},
			},
			{
				component: 'dag-chart',
				props: {
					task_dependencies: this.item.configuration_context.configuration.task_dependencies,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Update information',
					paramItems: [
						{
							id: 'updated_date',
							label: 'Updated date',
							value: this.$moment(
								this.item.configuration_context.configuration.update_date ||
									this.item.configuration_context.configuration.updated_date,
								''
							).format('YYYY/MM/DD - HH:mm:ss'),
						},
						{
							id: 'updated_by',
							label: 'Updated by',
							value: this.item.configuration_context.configuration.updated_by,
						},
					],
				},
			},
		];
	}
}
